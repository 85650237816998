import { reactive } from "vue";

export default {
  props: {
    modelValue: {},
    list: {}, // prevent inheritence of list="" on element,
    size: {}, // size property is for our usage, not for the element
  },
  data: function () {
    return {
      isFocused: false,
      testData: "test data value1",
    };
  },
  emits: ["update:modelValue", "field:changed"],
  computed: {
    value: {
      get() {
        if (isNaN(this.modelValue)) {
          return 0;
        }
        if (this.isFocused) {
          return parseFloat(this.modelValue / 100);
        } else {
          return parseFloat(this.modelValue / 100).toFixed(2);
        }
      },
      set(value) {
        let finalValue = value;

        let strValue = String(value);
        if (strValue.includes(".")) {
          // enforce decimals
          let split = String(value).split(".");
          let number = split[0];
          let decimals = split[1];

          decimals = decimals.slice(0, 2);
          finalValue = number + "." + decimals;
        }
        let newValue = parseInt(parseFloat(finalValue) * 100);
        if (isNaN(newValue)) {
          newValue = 0.0;
        }
        this.$emit("field:changed", newValue);
        this.$emit("update:modelValue", newValue);
      },
    },

    inputAttrs() {
      let attrs = { ...this.$attrs };
      delete attrs.list;
      delete attrs.size;
      return attrs;
    },
  },
  watch: {},
  methods: {
    handleFocusOut() {
      this.isFocused = false;
      this.formatDisplayNumber();
    },
    formatDisplayNumber() {
      let oldVal = this.value;
      this.value = 0;
      this.value = oldVal;
    },
  },
};
